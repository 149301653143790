<template>
  <div class="army-banner">
    <div class="close-button" @click="$emit('hide-banner')">
      <span class="material-icons-round"> close </span>
    </div>
    <img class="army-logo" src="@/assets/us_army_logo.svg" alt="army_logo" />
    <div class="banner-title">Be all you can be</div>
    <div class="banner-subline">
      Discover opportunities you never knew existed.
    </div>
    <mem-button
      class="army-banner-button"
      btn-type="secondary-dark"
      @click="openLink"
      >go army</mem-button
    >
  </div>
</template>
<script>
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  methods: {
    openLink() {
      let windowReference = window.open();
      windowReference.location =
        "https://www.goarmy.com/info.html?utm_campaign=2024_Spartan_acquisition&utm_source=spartan_account_event_pages&utm_medium=promo_banner";
    },
  },
};
</script>
<style lang="scss" scoped>
.army-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  background-image: url("https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/checkout/us_army_banner_background.png");
  background-size: cover;
  min-height: 150px;
  padding-top: 28px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 40px;
  @media screen and (max-width: $mobile) {
    background-image: url("https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/checkout/us_army_banner_background_mobile.png");
    min-height: 300px;
    padding-top: 30px;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 16px;
  }
  .close-button {
    z-index: $zindex-page-element;
    display: flex;
    color: #ffffff;
    position: absolute;
    right: 12px;
    top: 12px;
    @include cursorPointer;
    @media screen and (max-width: $mobile) {
      right: 14;
      top: 14px;
    }
  }
  .army-logo {
    height: 22px;
    width: 84px;
    @media screen and (max-width: $mobile) {
      height: 44px;
      width: 182px;
    }
  }
  .banner-title {
    font-family: Druk;
    font-size: 44px;
    font-weight: 900;
    line-height: 45px;
    letter-spacing: 0.11px;
    text-transform: uppercase;
    margin-top: 4px;
    margin-bottom: 8px;
    @media screen and (max-width: $mobile) {
      margin-top: 8px;
    }
  }
  .banner-subline {
    @media screen and (max-width: $mobile) {
      max-width: 214px;
    }
  }
  .army-banner-button {
    z-index: $zindex-page-element;
    position: absolute;
    right: 24px;
    bottom: 24px;
    height: 36px;
    padding: 0 36px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.56px;
    @media screen and (max-width: $mobile) {
      left: 16px;
      right: auto;
      padding: 0 24px;
    }
  }
}
</style>